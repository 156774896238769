import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import Loading from '../loading/loading';
import { Checkbox, Slider, Button, Card } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Filter } from '../../types/types';
import { setCountFacetFilters, setRangeFacetFilters } from '../../slices/facetsSlice';

type CountFacetProps = {
  facets: { [key: string]: { value: string; count: number }[] };
  filters: Filter[];
  onChange: (updated: Filter[]) => void;
};

const CountFacets = ({ facets, filters, onChange }: CountFacetProps) => {
  const [expanded, setExpanded] = useState<{ [field: string]: boolean }>({});

  const handleCheckboxChange = (e: CheckboxChangeEvent, field: string, value: string) => {
    if (e.target.checked) {
      onChange([...filters, { attribute: field, comparitor: '=', value }]);
    } else {
      onChange(filters.filter((f) => !(f.attribute === field && f.value === value)));
    }
  };

  const toggleShowMore = (field: string) => {
    setExpanded((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {Object.keys(facets).map((field) => {
        const entries = facets[field];
        const showAll = expanded[field];
        const visibleCount = showAll ? entries.length : 5;
        const needsToggle = entries.length > 5;
        return (
          <div key={field} style={{ marginBottom: 20 }}>
            <h3 style={{ marginBottom: 8, textTransform: 'capitalize' }}>{field}</h3>
            {entries.slice(0, visibleCount).map((countFacet) => {
              const isChecked = filters.some(
                (f) =>
                  f.attribute === field && f.comparitor === '=' && f.value === countFacet.value,
              );
              return (
                <div key={countFacet.value} style={{ marginBottom: 4 }}>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleCheckboxChange(e, field, countFacet.value)}
                  >
                    {countFacet.value} ({countFacet.count})
                  </Checkbox>
                </div>
              );
            })}
            {needsToggle && (
              <Button type="link" onClick={() => toggleShowMore(field)} style={{ paddingLeft: 0 }}>
                {showAll ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

type RangeFacetProps = {
  facets: { [field: string]: { min: number; max: number } };
  filters: Filter[];
  onChange: (updated: Filter[]) => void;
};

const RangeFacets = ({ facets, filters, onChange }: RangeFacetProps) => {
  // Derive slider positions from ">" and "<" filters in Redux
  const deriveSliderValue = (field: string) => {
    const minFilter = filters.find((f) => f.attribute === field && f.comparitor === '>');
    const maxFilter = filters.find((f) => f.attribute === field && f.comparitor === '<');
    const minVal = minFilter ? +minFilter.value : facets[field].min;
    const maxVal = maxFilter ? +maxFilter.value : facets[field].max;
    return [minVal, maxVal] as [number, number];
  };

  const handleSliderChange = (field: string, [newMin, newMax]: [number, number]) => {
    // Remove any existing range filters for this field
    const updated = filters.filter(
      (f) => !(f.attribute === field && (f.comparitor === '>' || f.comparitor === '<')),
    );
    // Add new min/max filters
    if (newMin !== facets[field].min) {
      updated.push({ attribute: field, comparitor: '>', value: String(newMin) });
    }
    if (newMax !== facets[field].max) {
      updated.push({ attribute: field, comparitor: '<', value: String(newMax) });
    }
    // updated.push({ attribute: field, comparitor: ">", value: String(newMin) });
    // updated.push({ attribute: field, comparitor: "<", value: String(newMax) });
    onChange(updated);
  };

  return (
    <div style={{ textAlign: 'left', marginTop: 20 }}>
      {Object.keys(facets).map((field) => {
        const [minVal, maxVal] = deriveSliderValue(field);
        return (
          <div key={field} style={{ marginBottom: 20 }}>
            <h3 style={{ marginBottom: 8, textTransform: 'capitalize' }}>{field}</h3>
            <div>
              <span>{minVal}</span>
              <span style={{ float: 'right' }}>{maxVal}</span>
            </div>
            <Slider
              range
              min={facets[field].min}
              max={facets[field].max}
              value={[minVal, maxVal]}
              onChange={(vals) => handleSliderChange(field, vals as [number, number])}
            />
          </div>
        );
      })}
    </div>
  );
};

const Facets = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { facets, countFacetFilters, rangeFacetFilters, status } = useSelector(
    (state: RootState) => state.facets,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(status === 'loading');
  }, [status]);

  const updateCountFilters = (newFilters: Filter[]) => {
    dispatch(setCountFacetFilters(newFilters));
  };

  const updateRangeFilters = (newFilters: Filter[]) => {
    dispatch(setRangeFacetFilters(newFilters));
  };

  return (
    <div style={{ padding: 16 }}>
      <Card>
        {loading && <Loading />}
        <CountFacets
          facets={facets.counts}
          filters={countFacetFilters}
          onChange={updateCountFilters}
        />
        <RangeFacets
          facets={facets.ranges}
          filters={rangeFacetFilters}
          onChange={updateRangeFilters}
        />
      </Card>
    </div>
  );
};

export default Facets;
