import { useEffect, useState } from 'react';
import {
  ImageProduct,
  ListSolverProductOptions,
  ShoppingListSolverResponse,
} from '../../types/types';
import { Button, Card, Flex, Image, Input, Modal, Space, Typography } from 'antd';
import { fetchShoppingListSolution } from '../../services/shoppingListSolverService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Loading from '../loading/loading';

const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;

const boxStyle: React.CSSProperties = {
  width: '100%',
};

type ShoppingListOptionProps = {
  mostRelevantProduct: ImageProduct;
  cheaperProduct: ImageProduct;
  recommendedProduct: ImageProduct;
};

const ShoppingListOption = ({
  mostRelevantProduct,
  cheaperProduct,
  recommendedProduct,
}: ShoppingListOptionProps) => {
  const [selectedProduct, setSelectedProduct] = useState<ImageProduct | null>(null);

  console.log('ShoppingListOption');
  console.log(mostRelevantProduct);
  console.log(cheaperProduct);
  console.log(recommendedProduct);

  const handleSelect = (product: ImageProduct) => {
    setSelectedProduct(product);
  };

  return (
    <Card>
      {/* <Space> */}

      <Flex style={boxStyle} align="center" justify="space-between">
        <div>
          <h3>Most Relevant Product:</h3>
          <Image
            width={50}
            src={mostRelevantProduct.image_url}
            onClick={() => handleSelect(mostRelevantProduct)}
          />
          <Paragraph>{mostRelevantProduct.name}</Paragraph>
          <Paragraph>${mostRelevantProduct.price}</Paragraph>
        </div>
        <div>
          <h3>Budget Friendly:</h3>
          <Image
            width={50}
            src={cheaperProduct.image_url}
            onClick={() => handleSelect(cheaperProduct)}
          />
          <Paragraph>{cheaperProduct.name}</Paragraph>
          <Paragraph>${cheaperProduct.price}</Paragraph>
        </div>
        {/* <div>
            <h3>Recommended Product:</h3>
            <Image
              width={50}
              src={recommendedProduct.image_url}
              onClick={() => handleSelect(recommendedProduct)}
            />
            <Paragraph>{recommendedProduct.name}</Paragraph>
            <Paragraph>${recommendedProduct.price}</Paragraph>
          </div> */}
      </Flex>
      {/* </Space> */}
    </Card>
  );
};

const ShoppingListSolver = () => {
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const searchSettings = useSelector((state: RootState) => state.searchSettings);
  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);

  const [userShoppingList, setUserShoppingList] = useState<string>('');
  const [queries, setQueries] = useState<string[]>([]);
  const [results, setResults] = useState<ListSolverProductOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (userShoppingList.length > 0) {
      const qs = userShoppingList.split('\n');
      setQueries(qs);
    }
  }, [userShoppingList]);

  useEffect(() => {
    console.log(queries);
  }, [queries]);

  const solveShoppingList = () => {
    const fetchListSolution = async () => {
      if (queries.length === 0) {
        return;
      }
      setResults([]);
      setLoading(true);
      setError(false);
      try {
        const response = await fetchShoppingListSolution({
          queries: queries,
          demoID: demoID,
          searchSettings: searchSettings,
          advancedSettings: advancedSettings,
        });
        setResults(response.results);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchListSolution();
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} type="primary">
        Magic Match
      </Button>
      <Modal
        width={800}
        title="Shopping List Solver"
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <div>
          {/* <Space direction='vertical'> */}
          <h3>Shopping List</h3>
          <TextArea
            placeholder="Enter your shopping list here..."
            value={userShoppingList}
            onChange={(e) => setUserShoppingList(e.target.value)}
            rows={8}
          />
          <Button onClick={solveShoppingList} type="primary">
            Magic Match
          </Button>
          {loading && <Loading />}
          {results.map((result, index) => (
            <ShoppingListOption
              key={index}
              mostRelevantProduct={result.mostRelevantProduct}
              cheaperProduct={result.cheaperProduct}
              recommendedProduct={result.recommendedProduct}
            />
          ))}
          {/* </Space> */}
        </div>
      </Modal>
    </>
  );
};

export default ShoppingListSolver;
