import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import './collectionsBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchCollection, resetResults } from '../../slices/resultsSlice';

const CollectionsBar = () => {
  const { collections } = useSelector((state: RootState) => state.demoSelector.demoSettings);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);
  const searchSettings = useSelector((state: RootState) => state.searchSettings);
  const updater = useSelector((state: RootState) => state.results.updater);

  const dispatch = useDispatch<AppDispatch>();

  const [activeCollection, setActiveCollection] = useState<string | null>(null);

  useEffect(() => {
    if (updater !== 'collections') {
      setActiveCollection(null);
    }
  }, [updater]);

  const handleCollectionClick = (collection: string) => {
    const newCollection = collection === activeCollection ? null : collection;
    setActiveCollection(newCollection);
    if (onCollectionClick) {
      onCollectionClick(newCollection);
    }
  };

  const onCollectionClick = (collection: string | null) => {
    if (collection) {
      dispatch(
        fetchCollection({ collectionName: collection, advancedSettings, searchSettings, demoID }),
      );
    } else {
      dispatch(resetResults());
    }
  };

  if (!collections || collections.length === 0) {
    return null;
  }

  return (
    <div className="collections-bar">
      <div className="collections-container">
        {collections.map((collection, index) => (
          <Button
            key={index}
            className={`collection-button ${collection === activeCollection ? 'active' : ''}`}
            type={collection === activeCollection ? 'primary' : 'text'}
            onClick={() => handleCollectionClick(collection)}
          >
            {collection}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CollectionsBar;
