import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../constants';
import { Facets, FacetsRequest, Filter } from '../types/types';

interface FacetsState {
  facets: Facets;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  facetFilters: Filter[];
  countFacetFilters: Filter[];
  rangeFacetFilters: Filter[];
}

const initialState: FacetsState = {
  facets: { counts: {}, ranges: {}, query: null },
  status: 'idle',
  error: null,
  facetFilters: [],
  countFacetFilters: [],
  rangeFacetFilters: [],
};

export const fetchFacets = createAsyncThunk('facets/fetchFacets', async (params: FacetsRequest) => {
  const response = await fetch(API_BASE_URL + '/get_facets', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      query: params.query,
      demoID: params.demoID,
      filters: params.filters,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data: Facets = await response.json();
  return data;
});

export const facetsSlice = createSlice({
  name: 'facets',
  initialState,
  reducers: {
    resetFacets: (state) => {
      state.facets = { counts: {}, ranges: {}, query: null };
      state.status = 'idle';
      state.error = null;
    },
    resetFacetFilters: (state) => {
      state.facetFilters = [];
      state.countFacetFilters = [];
      state.rangeFacetFilters = [];
    },
    setCountFacetFilters: (state, action: PayloadAction<Filter[]>) => {
      state.countFacetFilters = action.payload;
      state.facetFilters = [...state.countFacetFilters, ...state.rangeFacetFilters];
    },
    setRangeFacetFilters: (state, action: PayloadAction<Filter[]>) => {
      state.rangeFacetFilters = action.payload;
      state.facetFilters = [...state.countFacetFilters, ...state.rangeFacetFilters];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacets.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.facets = { counts: {}, ranges: {}, query: null };
      })
      .addCase(fetchFacets.fulfilled, (state, action: PayloadAction<Facets>) => {
        state.status = 'succeeded';
        state.facets = action.payload;
        state.error = null;
      })
      .addCase(fetchFacets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch products';
      });
  },
});

export const { resetFacets, setCountFacetFilters, setRangeFacetFilters, resetFacetFilters } =
  facetsSlice.actions;

export default facetsSlice.reducer;
